import React, { Component } from "react";
import BasicFilm, { createBasicStyleSet } from 'react-film';
import { homeData } from "../../data.js";
import FilmStripImageView from "./FilmStripImageView.js";
import { randomNum } from "../../Helpers";

const originalStyleSet = createBasicStyleSet();
const filmStripStyleSet = {
  ...originalStyleSet,
  showDots: false
};

class FilmStripView extends Component {
	state = {
        homeImageDir: "/images/home/"
	};
 
    getHomeImages = () => {
        let content = [];
        let nums = [];
        let i = 0;
    
        do {
            const randVal = randomNum(32);
            if (!nums.includes(randVal)) {
                content.push(<FilmStripImageView filmimagealt={homeData.slides[randVal].slidename} filmimageurl={process.env.PUBLIC_URL + this.state.homeImageDir + homeData.slides[randVal].slideuri}/>);
                i++;
            }
            nums.push(randVal);
        }
        while (i < 12);

        return content;
    }        
        
    render() {
        return (
            <BasicFilm styleSet={filmStripStyleSet} showDots={false} height={360} showScrollBar={false}>
                {this.getHomeImages()}
            </BasicFilm>
        );
    }
}

export default FilmStripView;