export const galleryData = [
	{
		galleryname: "Fire Performance",
		gallerytag: "fireperformance",	
		coverphotouri: "_DSC1017.jpg",
		photos: [
			{
			   photouri: "https://lh3.googleusercontent.com/pw/AL9nZEVN2xBouswZnvwwz4TrQANFOakaQfY7CMINo3nEd0XEY6KwbTd9aH5QxIRU2mMeIljAfUq_s3M7pSyxilamGrxoDd8BmfI5BAivL0rNKHpiIDtonFNcOsIA11sLqHMJMZGTn9fmfs2CLeEH63r1C_nr=w2793-h1861-no?authuser=0",
			   photoname: "Telluride Fire Festival", 
			   photodate: "December 2019",
			   photolocation: "Telluride, CO"
			},			
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEVLCARRpgrxGtXUQb6oYv85L9-Zu8qsXIqZs2aY3LVt8f0PP5qDSM93KeC2jKjTcSV8bFnZ_orft-UIahWlWOkEVgPcQ71lUHu5Nv_PeLgj-JnJof6lkv7HIen1t87PcYgboZ6K0iReUCCjxF8EmhhN=w2327-h1861-no?authuser=0",
				photoname: "Telluride Fire Festival", 
				photodate: "December 2018",
				photolocation: "Telluride, CO"
			 },
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXVBJmquguCoeQ363uVW7Y2TxWrnjzA0DqwOD8rLJiubqFjvY7u3hnzKYDp7fju_efzEkCXA-GdXfK4UDebterOB30Qb1gjbh-mv03uO0lrOA_ILp6M3qb5rGbhzWbJQLY_rDpSk-Wg9alowVUrzpHu=w2793-h1861-no?authuser=0",
				photoname: "Telluride Fire Festival", 
				photodate: "December 2019",
				photolocation: "Telluride, CO"
			 },
		]
	},
	{
		galleryname: "Colorado",
		tag: "colorado",	
		coverphotouri: "_DSC6372-HDR.jpg",
		photos: [
			{
			   photouri: "https://lh3.googleusercontent.com/pw/AL9nZEX44VyV-ORdK-X1ubNhsKCWpO6Ml_hiOhYYTnQqW1-RrMLB62wKe3h7maOUursV4rwJ4_UJvyPbFA-JYFTvmu9qMuzbBioAm_nls5znTJ5WzqKKhAtPA11DWJBnMkkWlQZo4CsfGaLvOyJJBd2bD9fZ=w3461-h1947-no?authuser=0",
			   photoname: "The Dunes at Night", 
			   photodate: "June 2022",
			   photolocation: "Great Sand Dunes NP"
			},			
			{
			   photouri: require('./img/Placeholder_photo.jpg'),
			   photoname: "Coming soon", 
			   photodate: "",
			   photolocation: ""
			}

		]
	},	
	{
		galleryname: "14ers",
		tag: "fourteeners",	
		coverphotouri: "_DSC0102.jpg",
		photos: [
			{
			   photouri: "https://lh3.googleusercontent.com/pw/AL9nZEWmEGdS6aAQ1jv6Rs4uLH7x349xWHAIcjQ_DQukjgANAEHfwUIFhb84iej0cvIsEDm8nfRuElfiwlVxYXD8vhdqoPYnYBssbPV-RtbuovOzo5S3sUHH0rnw9MxcFBu5vlY6E9RvNU0x-AjM0tKw50zu=w3461-h1947-no?authuser=0",
			   photoname: "Sawatch Mountains in Winter", 
			   photodate: "January 2019",
			   photolocation: "La Plata Peak, CO"
			},			
			{
			   photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXe2z03iwbA9mjlVMERpWZiU_7KKgpnhayQ2Gk3EypzxLjKDNnWd3q2mUNQNNHw7keJ0v7Gc7xK6vkrV8-U5sQfGZYvoUKAdSUrO_ZEQQe6aX-w0KbX9juFslZNn-tpZp0I-BFEoSBv9va_6F_sj-p2=w3461-h1949-no?authuser=0",
			   photoname: "Decalibron Sunrise", 
			   photodate: "July 2018",
			   photolocation: "Tenmile Range, CO"
			},
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEUN0cZmLG7O0KcLzqJRfoMm2R_gsAUfDP7KbYJsHNqMBd1LU7iaVTXXC5SSzULnEWUeHxJEygobrivQO3VtrKcON4V76Zcw4WJEAGfwZcZlQ3TpyPAoxzcWjjXqc-A9FF4XM-vnQZ59H2xksN7Kp6Tr=w3461-h1947-no?authuser=0",
				photoname: "Little Bear Peak", 
				photodate: "July 2019",
				photolocation: "Como Lake, CO"
			 },				
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXkFg-USNwOyeV5bv_XAwXC3sU1YvdgDTuK9ak41GTwADuV2nyK63nXIqNIwOs3_q3GWfogXuo4Teaid7naWDeyezhBPsOfn5iBYoksDfCryeVbE4Q2e2ysiXlHV5KzNZthgrvPOkwpYZTPnWyKvZrX=w3461-h1947-no?authuser=0",
				photoname: "Sunrise from Humboldt Peak", 
				photodate: "February 2020",
				photolocation: "Westcliffe, CO"
			 },			
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEVfhKdqklSwRFBn9W9-wcLQt6PBopRPrirTWC0JjAsaFRKgdO4hlx3-xP6hB3zLaKGmQYDsJYULC5fkmNNPDTxYEYbEZeZFOjFGLP1tVwHsxfVVzflPGRSZB5cAn_HnH02zv1Kz7PnwSkAHY5NdiPj1=w3841-h1922-no?authuser=0",
				photoname: "Cloud Inversion from Pikes Peak", 
				photodate: "June 2020",
				photolocation: "Colorado Springs, CO"
			 },
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEUkuwzjCmmH3Z8FS22qZ33_03gaP6GEwwVDY-FINTvuJLbcFTLx419C8gioBNO7TgOdrP2qhg51dH7C4zTxmECLDLI7A8hmzl90OeF8KnCmLsUCfVPsnzM5jt4gavB5nniuLTC5VqQCqXxG6L-m93-j=w3841-h1281-no?authuser=0",
				photoname: "The Elk Range from Castle Peak", 
				photodate: "May 2020",
				photolocation: "Maroon Bells-Snowmass Wilderness Area"
			 },
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEW87WQ36uSOfOCmA5vsx54VT4B77OqIxPwB99ftOHOmRMhpKolnMliIYVEivkZvwRVFjgroy-Ck3fxAz6E4Zsr0qyYIrxU1nTBp1mF5M8UIINAI7Fpgj4fF3wdwLBBg7NIxI2rvLhdHiqwztuEgTpe4=w3337-h1947-no?authuser=0",
				photoname: "Moonset over Mt. Missouri", 
				photodate: "July 2020",
				photolocation: "Collegiate Peaks, CO"
			 },
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEWGURQSBgu-ESi0XlFtdzhPDsGvzU3Oxeuv71pw1g_0Xr__YAhcl9C0rpnAH-XU81yq7xMJ3gzD7_mypHMlHbuNa99o-D4dvHsRkXU4-0hbkDh6A7z9HOsQUc9o6DpwyhcFg-DJcGi5H4-IGra1YktK=w3461-h1947-no?authuser=0",
				photoname: "Satan's Ridge from Mt Snowmass", 
				photodate: "June 2021",
				photolocation: "Maroon Bells-Snowmass Wilderness Area"
			 }					 	
		]
	},
	{
		galleryname: "Switzerland",
		tag: "switzerland",	
		coverphotouri: "_DSC2318.jpg",
		photos: [
			{
			   photouri: "https://lh3.googleusercontent.com/pw/AL9nZEUU7TD91y40LaaMMd-Chm8Et07dqDAGkbaGvmAGM3m3LICwQKhYsHEM4cjKJEhDsEsXYKstmdCQgrXEKPkcYJqB96q9nViY8pcWDy2IFMOcMZx2stR-RJxd5SycKqDEcsw2mqMCHuqFUIT_8KJsmdVy=w3461-h1947-no?authuser=0",
			   photoname: "Mountains near Leysin", 
			   photodate: "January 2020",
			   photolocation: "Leysin, Switzerland"
			},			
			{
			   photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXD7i45IN-n2mgrQ1hZIKt1glOqf3dhAiVa9vMG8nUvkkyHasehlqAdF0rq5cJXdWVRXE7G2Q0WOjDgY-xXGArDcUzOB0ENQ43xVVIJLjcnOTiPMyuRySaVR55Nz9pkYJbk9xRpSMVmJWbOG9-72yNJ=w2921-h1947-no?authuser=0",
			   photoname: "Leysin in Winter", 
			   photodate: "January 2020",
			   photolocation: "Leysin, Switzerland"
			},
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEUB1YADUUIz7SAhUWG2PclFnce4SMVymQE7QzEEX662xdCeUFN9lFwFQaLSqDg_3bNUvvidEvk0c2K6wrT3ZZYwuWTvBh_XixaMDEFTc4w9HTkNkhkt7RNUr4FMvYO8Tj9YrF-a-9Iu59JoTgY7jkQa=w2921-h1947-no?authuser=0",
				photoname: "The Matterhorn at Night", 
				photodate: "January 2020",
				photolocation: "Zermatt, Switzerland"
			 },			
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXLrYE6Zl_PoSA51naVmw-a4Z46RP_WLs6DpXlQnGzCGtjz8VzbV2jap0a9MiNmeQ5ZU4HUeepnft2ahfPZjt7w7omf3aq1duGMC234VRx_NIOjEs38wGzIP0dvXYPniM0NJOZA2RBQP3hP1q37uu5v=w2921-h1947-no?authuser=0",
				photoname: "Zermatt at Dawn", 
				photodate: "January 2020",
				photolocation: "Zermatt, Switzerland"
			 },
			 {
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEWNXkwPvcZ5aCiylLUySsQK-3e-AGGHl_idTQCLNcDlPdTB_WBH2hiCjHtXfF7lmU0dmvP9IbZ2vDMgyuY0XErsNKo5_eH4mIezYu_tIEoD84rlydjiGYecjeFSTxPDeWRYquyHvpf50fDYWIYV7INd=w3841-h1922-no?authuser=0",
				photoname: "The Matterhorn from Mt Breithorn", 
				photodate: "January 2020",
				photolocation: "Zermatt, Switzerland"
			 },			
			 {
				photouri: "",
				photoname: "Coming soon", 
				photodate: "",
				photolocation: ""
			 }
		]
	},
	{		
		galleryname: "Utah",
		tag: "utah",	
		coverphotouri: "IMG_4584.jpg",
		photos: [
			{
			   photouri: "https://lh3.googleusercontent.com/W12p4FgVFOlBKPgrFJiFqAijZe-PU7FdXehF-jawKNyGDP6JcZblFPxI9Z924ZtT-i4SDPnnHb61krQ7AG8eJ_L1GMZRjZm1zq8lQWk7XJjeXNBFPY-z7d5rf7rsTK6zVF4rG8bJKCVTEcvJg8II1xKFbwfMy-rGMdqrdH4QBwNREF8kcL64Kn3y6VI7gksDL4_g70c9cTmVxUee_UEIAOBJJ1S_xlrsgwxCvLNZsnrdKdtS9TV5ddE-Nm9u2X8KksFGG-IYenhR_NEBPGGtQkXAKfRupgx813q1V5sqz7G_8BgavMYU2FIDYCRld7lhYbxs2m7yQ0e2dZvVqEvj7qtv3a8Mk4prV3n2V_z5b0TJabKxqexJEqr4DWLFMSzBdDKEqGEq4poiup9mXAfN-m24f8EMY6UFt6X_g_zxG0EQha5XkVbBIK7X7rSL8wwqyLEcLU1ocGmC1fexVHuoyv8CZ5XXQ1q_RswAl7jyrlcBQJ953q6w398J5xUsp_PYIf_1FY8DjM7dQhxTRVkyK75jruYzXjOIb4u1A7AU77j6eKNhrvwWB4rHCx24aZea4HXoyMww86jHDHOHDf9L8YfMMZUnVHSM-5q2l7-_ZS9JXl1-5H0ua9IIbbD8ap2LjWN9kTQpNVyi18KCkZ9ATqy3uzesuDebZ5JdYo_iqnjQ3eJhs9yhxrETYCz2yJ-7xAjXpBUqperjM-hMlMhCNSXIUejic-8Hyj7HOw6dQoMj8Kzj=w690-h296-no?authuser=0",
			   photoname: "Buttes In Sedona", 
			   photodate: "November 2016",
			   photolocation: "Sedona, Arizona"
			},
			{
			   photouri: "https://lh3.googleusercontent.com/KOiwCziHwMlnGT-v8slP82PVyl9xF06yaPEiokPCbi3w88RZzm9eJujbjfpzWsw84AOul_UFYVgmfYUaFDAxFmahImH6iBdc5IvJJP666iD1e9PbOZxvzijgmqITq2qmg9ECisKPbHgTfU0gNWTTiornCT8p59bYTXeE8xqjKo6ZsTYB015KgSvkYdGeQQY3kiELDkPrjwa55w3bBk4EutW0fEjaNLh6Q9BuoEbQVbPnzRbNJsUhWKBVxAX4pQJ4Hdl8WtdInlLBkNn7LY_1nMu1nItd4HhdBSMdsayLfK-HmRF69mdEACwCoqT79WilXVHEnBnX3PznM5DXznW9sBwJfUd_PEulPkOcLE1Q913wJGZ4nwNrs5GT-Js-Q9vKMlqMvqtYe4Ymd0Q-vOCXtFjPSlPFngndKANIdUJkSbhghtMdtCsiejIO3X6r_rD3DlFglTvglUeyaMUsyry8SzEGRkZiegvM862O4I9_LXxXb8ZF6CTOvF_OkovA7WdkWyha7NCRH9ucU3gqaShz2Du1Cb2mruxxguyygdubJ85UTGPnltBInT5OdgDe4k-NhFwA9VLaCs8qFUOIBUVP1ujha9wmhkl1B6DlPdG1rs5uWzjpIjX8jT0XYdSovpHkEDtZ-ZJUln0KpM4VSlnnk6u-2nbg6tZ1U8xudGeTbMNo41DdLv1TdhTR9_pFkTvmcITugvO8zJwNDIt3qCF77KoN-F2-QwTazTKW04H-irZE_F_1=w700-h394-no?authuser=0",
			   photoname: "Sunset on Antelope Island", 
			   photodate: "July 2020",
			   photolocation: "Antelope Island State Park, Utah"
			}
		]
	},	
	{		
		galleryname: "Washington",
		tag: "washington",	
		coverphotouri: "_DSC4067-HDR-Pano.jpg",
		photos: [
		]
	},
	{
		galleryname: "Fire Art",
		tag: "fireart",	
		coverphotouri: "_DSC2226.jpg",
		photos: [
			{
			   	photouri: "https://lh3.googleusercontent.com/pw/AL9nZEU_RqNdVPHb7tv5MvfZZs8BEp-Kc5-aChhu_ORkeVa_i6yg1t6TYRNDlGVYMxalQC0mRsvRgOKTZyfpq7dQa5ALPTVDnboqrq9GFyH7YZFQpADwBaZWn-nnt6R5Hpt8hFumb9xZCw3k_U1FIg5Bxcgl=w2921-h1947-no?authuser=0",
			   	photoname: "Fire ceiling", 
			   	photodate: "July 2018",
			   	photolocation: "Colorado"
			},			
			{
			   	photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXz3i3OJA6apwIRV23jvp9QVLK8gz12h1V5GyNfc0sO_cLYa0rjsV0IPWz32l__6FizwrDD3ok9yTi70hVvoKwXyvDHNmUsSsJPsVEaRp7qySC8OaqF3Miwy6FjL8UwinKLXMm_sQe6C_PqSw4YetYy=s1947-no?authuser=0",
			   	photoname: "Fire strings - flame effect head", 
			   	photodate: "October 2018",
			   	photolocation: "Georgia"
			},
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEVY7bCF-sVbO52LJzdfGy3l7_-c9_DYyyjwmogn9gkvwugGTMDYcdhZiEBWqDtn_Vd6TlLh30c7jy9e5R3eGPAMtetJ-QDCV8CG8nLPARGoGRQxCisRrcik2k-Gbp0pv5xZPtoMXK16pTrWTIawAfRy=w1299-h1947-no?authuser=0",
				photoname: "Paphiopendulum - Flower", 
				photodate: "December 2019",
				photolocation: "Colorado"
		 	},
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEVAMYBuzPjQsqLNNVejkZskrHB7Wta1_MvKDUB6JAJbKPD-CHFMJNfU8_ZnDUbgzh-464bmt6d_vCbJKxkfufSujgex_Ys6Goncfhkp03uoW0rmXoZ0rI0cNyyeAN2FZ711faFww8fHRuz3WUufXfhy=w2435-h1947-no?authuser=0",
				photoname: "Fire Tree", 
				photodate: "April 2022",
				photolocation: "Colorado"
		 	}			
		
		]
	},
	{
		galleryname: "Iceland",
		tag: "iceland",	
		coverphotouri: "_DSC8181.jpg",
		photos: [
			{
			   	photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXn3EH6e2W9Zqwnhz_cTx6auCkKebihot_ff37SU3yKQVLF-vCFTPijdSTAUfF7xnkuRrNkMADX5l0WLprBdYj_PmBYWsquQnfCkYTDaNPm1Ftf42b1DULTPvMyTsHUZaDUeqqVhjgl1N_rVJgqxn9k=w3461-h1947-no?authuser=0",
			   	photoname: "Alftavatn Lake from Laugavegur Trail", 
			   	photodate: "July 2021",
			   	photolocation: "Highlands, Iceland"
			},			
			{
			   	photouri: "https://lh3.googleusercontent.com/pw/AL9nZEUh0SWvuOWSnc7zZv4e2MVC4GXg1z_ePfs41Wpakcz_0MX-ogsEcFbrsvVuno8GWX94TpHoe0skZvZ23YuI91VjNbN1zIpC98O0Cdb9_W6dd4oZg7bPnFy1QoaUsWsX_ME-t1h_rfQ1mFusflWwsmSo=w3461-h1947-no?authuser=0",
			   	photoname: "Reynisdrangar Pinnacles", 
			   	photodate: "July 2021",
			   	photolocation: "Reynisfjara Beach, Vik, Iceland"
			},
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEXCzSn1whuco5N_DGkHVE21GB28efH3YbZ6jhnLjlV-OtOx4RVIf62r4dg7E0KVtfCFpD4wVMwThPw3jnsJ4B9KTdBqJpkHCC8Jz1dYB8AivCNM2_yV3dnApJLSXExZ20UNIMQAopTUUo9AfiCga0Sm=w3461-h1947-no?authuser=0",
				photoname: "Cave at Reynisfjara", 
				photodate: "July 2021",
				photolocation: "Reynisfjara Beach, Vik, Iceland"
		 	},
			{
				photouri: "https://lh3.googleusercontent.com/pw/AL9nZEWJeMdbUTOP63uCyN9WF-pHyrl9tioF7_Jlsr_t2JN3jHQbFfEfnNN0xL9QIoSROfFl6luic2s1O80CjlhcFaw7Ufx2HaAoo-kIdTA-FcLxiHFtPGBy4js4Ky40Uhj0TXgVmUaTNYU1_w_RYhZhRbFj=w3461-h1947-no?authuser=0",
				photoname: "Skaftafellsfjöll", 
				photodate: "July 2021",
				photolocation: "Vatnajökull National Park, Iceland"
		 	}			
		
		]
	},
	{
		galleryname: "Wyoming",
		tag: "wyoming",	
		coverphotouri: "PXL_20220904_014330341.jpg",
		photos: [
			{
			   	photouri: "https://lh3.googleusercontent.com/pw/AL9nZEUcwnx8okJ1e4oIuqD289na8W3_TB_byepLJkFNoqKaLGPfvNmRo5Kcn8BqIbeQAh0-5r5KIiaWIX7SB1xpmsXeiG2-e6hAjoBND31YChk0AUvm-cIs83DzTlWA1oVtIN1tWSrGkzyYrNz-91A9a0dm=w1920-h1080-no?authuser=0",
			   	photoname: "Cirque of the Towers", 
			   	photodate: "August 2020",
			   	photolocation: "Wind River Range, Wyoming"
			}			
		]
	},
	{
		galleryname: "Montana",
		tag: "montana",	
		coverphotouri: "PXL_20220821_021615470.jpg",
		photos: [		
		]
	}				
]

export const projectData = [	
	{
		pic: "https://lh3.googleusercontent.com/pw/ADCreHcaBF5F4jfrWOQCslkEyvwXc62LXXk7g6CtoA2OI1_uJqRCkedxwSe9pjW3ddBz0aMB844ZkVUiEbmKBHeuUcMHbSnSjKrbv7BOuDImEt1KTZm3kmOvt6tUDrXSOynA-abAlg8yMtvJhDVormesFfDs=w1440-h1913-s-no-gm?authuser=0", 
		projecttype: "build",
		projectname: "The Static Void", 
		projectdate: "June 2023",
		buildlink: "https://photos.app.goo.gl/2154jRLj5PDEioAz5",
		description: "The initial iteration of a fire art garden composed of pieces old and new."
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/ADCreHf9JYGtSlGAHVghLV65PTh81CMZNH-Oe0tsOiePmcvCs5ZgXmxMddvKZ6uF7BzKnl1FWkt-0nYnUrigmwE5iPxeqNKzoIRs3RCRhTCkKTYgAwHhPF9ruwyP3taCEOqANHGVsB8URG88tqtOGe3GO5PQ=w1508-h848-s-no-gm?authuser=0",
		projecttype: "build",
		projectname: "Merrrr-y Go Round",
		projectdate: "April 2023",
		buildlink: "https://photos.app.goo.gl/5hAtoXNc6k5KJbsL8",
		description: "Originally a stationary fire project, I repurposed a small truck spindle and mounted the entire sculpture onto it, adding fire effects. Participants, for better or worse, could hold onto the perimeter as it spun around. Getting on it is much easier than getting off of it :D"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/ADCreHeMMeZJY-_00IumrmHy0RdoPo8IbGGathAoAogjthWaaBxgieV1lP1qm4w_XuY4LYTlrEl23yAEOYWlWRZ5hLkiGZNSNMNdblkmGIlmY8h_AiIu_R_pM8n51nTywXQwv0kMQeu28luSNrYhsmkrp3Pp=w1440-h1913-s-no-gm?authuser=0",
		projecttype: "build",
		projectname: "Fire Column",
		projectdate: "September 2021",
		buildlink: "https://photos.app.goo.gl/3aYtEao4PfqbiVPN9",
		description: "A column with a free-burning fire effect mostly used as a conversation piece. Originally an LED sculpture, shiny panels and the fire head were added during a second phase of the project."
	},
	{
		pic: "https://lh3.googleusercontent.com/I7t48tQuJdnJqfNZ224rlplAeFXPhtFykfG65EH4djoDq8FHZo5AbW8RVWBOPrDJE46RqqYSBAHbpzpFXwvjC6J2xN10yNdWUAGzBKe7QI5H4ZxyBj_wfqq3JLkWjH1fsfrhUa_mcgB3bupO5sJkoESO-khCEpMD9kt8cV3IsL_tNCarTSwOD5qc3bS0u3toymWFkQRO8e6UDixyyhJVlvtOaY8i9yKlZvQqGN2k_S5sAhSiMQGYicY73jGAXvbwoOJ-ZPWDIBjZQMuKiUsgWdtzVHWcRL2nIbx93yxOcJ1WbE_axKSrkYYjEdQBsoffxE0iNQE3T0fAwXaufgRICsBvbpnF7Oi2iTfsnUc50Yj6ArHw0EYGIhiSZMtVBILs8Csx2upTwIKral_XFtWNh8dTe8afntVjvTpAu0OddvrGrysvwD4YLuE2yn2fYO-_rgIJMeK8eCyU04T8bUp1KbY_BPaX-P2S0S8u_a8hIIqcW0TiU3lecDos1BQ-jy4oDFZx92OJ5-5b2zua2upV2za9Px-GiRZzuSy1PAErXmLq34TSklTHfVMLqlrD6vO8zGs1WuNMhqPK-BwegS-3D3pnEdYCGqB0W2Zt72FwxyhixDQudwODtjkCLMSoBEbyLUnckXDQaCngd6sK7bZ0GXe3FrNV4Vo1UZxuHnnEt0l_W19L5xCsECi5tYdrNQuVgqkIzrf0i9cmB5Ti8VUyvT_kUPLvV52JkW_FwKe4sMyBKtey=w623-h830-no?authuser=0",
		projecttype: "build",
		projectname: "O Cam All Ye Faithful",
		projectdate: "December 2020",
		buildlink: "https://photos.app.goo.gl/j8jSB3BFanXscUXL7",
		description: "An ad-hoc project consisting of a lovely holiday tree made from old band saw blades and decorated with climbing gear. What better captures the spirit of the 2020 holidays like a bunch of sharp and pointy strips of steel? "
	},
	{
		pic: "https://lh3.googleusercontent.com/U4XDmqxqHC8MuH_RXGFm69QFHN9-a-3gCe-oJFcBMcK7MwUo1EjIhutn3doVFoLULN1TUf3a4KbvnRumSaQ_rd3lT8JqTrJJ5WZmPScG9I4SVbcN6LWRkSBl0iaypUa8B4skOg4T0q1zcc5Zns_-BykdjDPKMo3OnNvbTaJH3wEN7NgQcp6GJRIVRudJFYUS-M-mOSsskXFIPtAVw48syyvxjtd76xOPCOLjZ7d1E_uDgMRBcfoQLBYXWYEYLUeL1I-qA_PhV2Svu5FyuuOg8eWdHqqzpT13sSlWUnfUZEn_oMrnVA9s94otU_tVm_upP2JG1Kztkt8jswjxfjgZ40csdtfgNBSvJRfo0PzNwBIrfJ1hbH28t-kHmLSRg-BiMGp9TMCQ_q2EItEOfFiD2BhSVbWBvOCiqLQgSFcUyqlRNX43Xq38YVZbn6WMtAkPP3LGUr9HrYSzwluPhZKAwI8v6AaFvQv0mgAyyfBsaVXAQ89fl7Zmr3x2c0x7bh4YmeraQ70ekxnhuORVXHKGvFvLArBo-zXYp16rB53swbwObUK1_Y1pDUlhYWkIpKkWW408m_jqAKCC8wy8bghK8bITWNIgQDj5laGJ3gh2eopJf5NiCMWlMg2pFdm-8eFJ0mw6k5A-3zfszF0zfqiNIRN3yNzUvR03y9y2wX4JTyWBm1Qh1RGCMQE3dM300SFLGRLQmbOxXWEWtYmX_NJ9QYqqaTQKqfmNLeEl8_Ow11RVlGX3=w700-h525-no?authuser=0",
		projecttype: "build",
		projectname: "Industrial Media Cart",
		projectdate: "April 2020",
		buildlink: "https://photos.app.goo.gl/eSnhwWiqwrr5DY4C7",
		description: "A small project I worked on while stuck in lockdown during the beginning of the pandemic. I used various gears and other parts in a nearby scrapyard, cleaning them up then bluing with oil and a torch, and added casters for mobility."
	},	
	
	{
		pic: "https://lh3.googleusercontent.com/RkovVETQx-MFq8fUP79Tw0MFsUQ-yAEirPHa45bqwx_gCrzA6YWNqBxejW32Ksov17w_PTBhuGdcwWtWxnsl6OgqjHT100vpJ6ieXenqnArmjFS3DlRxY6Mm91VyBRS64l4UhE2jGGlpCl2L_K1bd76wAQm-wcNQjNGjxQjcP1d7YTRRzQMYc1VhjdIuycmx3Es2HeIdDsEqaP3k794RezWaO1bWFnwGVUc4NnkHJw9H8_U-RUWMI1XWMR5KkGCDREpQ9HxASkpamm1JLrqiL69MzIpdDDhmbfUrl4f2SHyZI_zrAIbtj4zZvMKR3rVBNCsgUVW5UPlvSSbnWk7KmSkwQfvSS_4woq1RH0PN7-9XSpQ7UTxnc5YHjRlwwSeY5Vx7rsAhliY-96ZgMgmAQSEjp9mzrt7xUpe0JvVx3v7UAxGSrqfAs_hj3joruTO9Zh5S-qL-VSZUmokNFvhtHFjxnoYX450JtVPytN_QXa3G_xUBUrv13wzChKdBVByycWWGmX3R4FEOouEWqMRxA5_ZPXd8sRJ0MxjorbEDV85YxsX3JdbUnbdv7IwdGMybmM2fB7LGg8_3RAtTTxNEc4IKXadmQQuUE1aGtkuRoEZhS30igOTYBm-jxflBU8lLtosyZUxxQE4hSGpF0exr9oQTKIOtSRNlO35zuRzZXeONDMJwSWzsTaddXhAzvUDVPOOpGeofVNzMxVq-gSfn70i392va5A-ol56DTSZhFA0QANZ2=w700-h467-no?authuser=0", 
		projecttype: "build",
		projectname: "Reflection Erection", 
		projectdate: "December 2019",
		buildlink: "https://photos.app.goo.gl/rMxXpfZ7FRvqUcHU6",
		description: "A large fire art piece that uses reflective sheet metal that I discovered sitting around in a scrapyard. Over 120 metal triangles comprise the piece, which is about ten feet in height.  The project first appeared at Telluride Fire Festival in 2019."
	},	
	{
		pic: "https://lh3.googleusercontent.com/ktLa2SMLCoXyrg6Py_laU_TQnFRfqNs9kkabpsXGLVI9WZEoPrdnekc2mXTGUTswRgDgAW4049ZUtoNtUeo5THdfkKkSzCo4HyCF2wqukfyWILl5GzQLJ9SyoICz1cgFyLUzyis2PuAfZUJGkqFc22_L-rzbJZR9v1YchVKdJBuBD4riEyOhr1-SwcadX9c9swA-00joqI1mw6aR804d3sWG8zsXsXPX0SaaZJVFs9HTsGi9Wv111X27wV01dbVhMTKjCh8MSNaUQxkn1XmV34B4YCC5fNAsupBjSLN3zfoh42CkJFNm125pL1F_U5MIp-JCSG2aVOfFiaTbj6KX3lUpX6s25NnNzadTJyDY-1gS61H3DFSi_3NX7afL4twnZXp2bcCgbHPIv8bepT9gDVdgVugY42GfGpSo0BJrUw9qcCA_SCRuExe7L5tJeRwmUmiUTj-lAICZRkzTViJH23YPcJu8C9Ln8XZbOQoWAkpM3W-lCdTtfFk4EL6l7AAYlwpqM9yLe48wFjEvB84JBGcLcPZ5Ygmr4KHH9TSUga1UXm876Kxtl8DGT6gsdI1IScXudhaqPtARCdyKa5gpa8mc5a00C3CsgmkdVV0T6eP1TaWeZChW-FOwAwpFfzHuuccAGTB5BBNY5JyC-JRAzL0BjpyLFwatlbu0bgVpdKj-5y_nkxqJycwP4RSOjYiJPgvc9ef9ECQUWv6mOAFB-8NcgZeg8YLB-8A_DR6Nr5R_cFLd=w700-h560-no?authuser=0",
		projecttype: "build",
		projectname: "Fire Strings", 
		projectdate: "October 2018",
		buildlink: "https://photos.app.goo.gl/4373ucdQ7XrnbWSv5",
		repository: "https://github.com/automorphic/fire-strings",
		description: "A flame effect whose components were triggered by a very improvised string instrument using violin strings.  Flame effects were actuated by placng vibration sensors at the base of the instrument; the sensors detected when the strings were sufficiently plucked. Fire Strings was a one-off temporary art piece for Alchemy 2018, and has since been dismantled."
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AL9nZEWmSFgI5NTzv7AC1NliBInS_xKtStz-TtPGQr4pF0j-TP8Rvq7_FypuyVJ1smItcyXaiy-t6dU5Jkz1y5T_R9fg_AdJpk-cawtnN_sZaS6smPgYLOG_v0QzCf0IjuDerYbBmw7V2h6gx20HP6hB2BpZ=w1571-h1947-no?authuser=0",
		projecttype: "print",
		projectname: "Fire Art Frame",		
		projectdate: "January 2022",		
		description: "Nine prints of fire art photos taken from various events and festivals between 2016-2019. Frame: Mild steel, rusted with coating, addressable LED backlighting",
		buildlink: "https://photos.app.goo.gl/RYBdXpwkZwwVisqB9"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/ADCreHc4w-L3-9ByicxOOSzGOlJWT4wiHvYnfOLbU-edw0B7TK0vWV0CsZL-DANDyD-hUaDWJhWVQBNiJLTFqRsjVZkzUzsnhQ9MiNdhYQvONPy7kiLXZQrPR4AlIRUjTI_byUhm5PQOwmcOEW3igwdvu2kJ=w1361-h1913-s-no-gm?authuser=0",
		projecttype: "print",
		projectname: "Alpine B&W Prints",		
		projectdate: "June 2021",		
		description: "Illuminated frames of B&W photos of Wind River Range and the Sawatch Range. Frame: Wood, paint, LED backlighting",
		buildlink: "https://photos.app.goo.gl/qdczuYod1ZksopKe6"
	},
	{
		pic: "https://lh3.googleusercontent.com/pw/AL9nZEUPKCiZWX8PBDhSLYBecI4JXodULd3CoLuMuQu5rzRJkaZTcZfSrkCWl_DfHBv-mqKZRHJURyD8XiM_ZpRztfbzd8FyTE1wuARyZmmp7eXRv_XH9j6fqYO8WbCyJm0OBpmjoL67adubhEuMdQpzeKNb=w3337-h1947-no?authuser=0",
		projecttype: "print",
		projectname: "Crater Lake Frame",		
		projectdate: "March 2021",		
		description: "Custom frame of Crater Lake. Frame: mild steel with green patination, LED backlighting",
		buildlink: "https://photos.app.goo.gl/SQ4vXd2W7Fz8nXyeA"
	},
	{
		pic: "https://lh3.googleusercontent.com/fobZ5W2xIvttEV7mDnhTGhc_kNYAv_kvvEL0S3RRHaJD8Tjt3eRdGP6TE64NKtxm9RtTDI9Cg29FfOriwaZAwz3nekWAuDF7cCF5naX1rPSw3yff35myWmsuPaP3lkxYXawD8tSiZ9B9buyqcVVq6mlLrUAa7d_l1odBT5TIzVmmNdnHM7w0lCDsDPQ1NIm4SsfABOAn9eW3VDDYLwSbb30QoYde56IRJ1TkRYKUXD1sE34iZR_MEJnyWpC1yuh76kBPspzQFOFY-lrlbqpJHofhlZLMWu5xeT2x6EqS9d6ty5FEvJCCbUWE8RIAN86KwsmpTAD3vGxzneR5QHY0GR5X5PtzB444aAS3NHgdgzIWfV8EQHJ81TzsVBqKiN0M43u5_Qlj-WwDsI4RdBSGz_k1tO2cdOrlkEJFvuzoMAxNiIrwuWkZhiVoZptSEuezIcrn5i9PN2BMK6AnQF5QfylMEfk-h8PKx8w0UyAQN1wdc1dJyPyarYs3P4RtoRFa2aUA8Ky14-7KPl6B2d7oyRxpp_sbMVpp209qqPjzL5t4e_0hNLPTbCETRJVlqgdSMzLksrdcJEffFCbuuYQ5hN1NmzMC_opIC_h80_5TTIQkbab0CWFXvF93snva_pJrYkdSXwH1sfGN3hS_4Q1I_Lt4xuUeAdg2GrHpSMKuyRPkv__7iOwCd-SH26kL5OU26GmByJRh2eMu_M1YzTTXlWNk-NW6aESl1OTI79sAUYFVL2DG=w700-h525-no?authuser=0",
		projecttype: "print",
		projectname: "LOVE Effigy",		
		projectdate: "January 2019",		
		description: "LOVE effigy burn at the 2018 Telluride Fire Festival. Print: Dye sublimation on metal. Frame: Mild steel with bluing, red LED backlighting.",		
		buildlink: "https://photos.app.goo.gl/EiMNs8S96nFfvEpc8"
	},		
	{
		pic: require('./img/Placeholder_photo.jpg'),
		projecttype: "code",
		projectname: "Sunshine+Redcloud",		
		projectdate: "January 2022",		
		description: "Custom hardware project for LED effects based on NodeMCU/ESP8266 microcontrollers",	
		repository: "https://github.com/Automorphic-Systems/redcloud-led-main",	
		buildlink: "https://photos.app.goo.gl/dXEZWVHerm5UJnwF6"
	}			
]

export const aboutData = {
	aboutcontent: "Initially inspired by my experiences at art/music festivals and the spectacles of fire and performance that often accompanied them, I picked up a DSLR camera in 2008 and began to pursue photography as an enthusiast. I gravitated towards these subjects as they reflected a certain intensity, capturing in time a certain empheral quality that is characteristic of artistic expression at these events. In tandem with event photography I began to take my camera on the trail and eventually into the Rocky mountains of Colorado, which has become a primary source of solace and contemplation. The awe of being in the presence of something intense, unyielding, and austere has been a springboard for inspiration, allowing me to return from the mountains and participate in other creative endeavors.",
	aboutsummary: "Learning to use the lens to effectively capture fire art and performance as well as large scale art installations ultimately meant becoming better acquainted with these medium of expression themselves. I began to explore the use of LEDs and metal in projects both passive and interactive. I also began to incorporate fire and LPG based fire effects in some projects, and since then have produced some small scale fire installations of my own. As I learned how to work with these mediums, ideas began to abound towards how to tie these things together in a more interactive manner to produce more engaging works and installations.  Can the confluence of imaging, light, fire, metal, and hardware produce an interactive experience that is awe inspiring, emergent, and meaningful?"
}

export const aboutImageData = {
	tiles: [
		{ photouri : "sq_DSC00178-Pano.jpg"},
		{ photouri : "sq_DSC00890.JPG"},
		{ photouri : "sq_IMG_0046-Pano.jpg"},
		{ photouri : "sq_IMG_0436-Pano.jpg"},
		{ photouri : "sq_IMG_20190505_131911.jpg"},
		{ photouri : "sq_IMG_20190729_201946.jpg"},
		{ photouri : "sq_IMG_20190731_094017.jpg"},
		{ photouri : "sq_IMG_4282-HDR-Pano.jpg"},
		{ photouri : "sq_IMG_9759-Pano.jpg"},
		{ photouri : "sq__DSC0102.jpg"},
		{ photouri : "sq__DSC2588.jpg"},
		{ photouri : "sq__DSC2590-Pano.jpg"},
		{ photouri : "sq__DSC2601.jpg"},
		{ photouri : "sq__DSC2838-Pano.jpg"},
		{ photouri : "sq__DSC2887.jpg"},
		{ photouri : "sq__DSC3589-Pano.jpg"},
		{ photouri : "sq__DSC3851.jpg"},
		{ photouri : "sq__DSC6099-Pano.jpg"},
		{ photouri : "sq__DSC8766-HDR.jpg"},
		{ photouri : "sq__MG_6709.jpg"},
		{ photouri : "sq__MG_6738.jpg"},
		{ photouri : "sq__MG_7242.jpg"},
		{ photouri : "sq__MG_7635-Pano.jpg"},
		{ photouri : "sq__SAM5342-HDR-Pano.jpg"},
		{ photouri : "sq__SAM5727-HDR-Pano.jpg"}	
	]
}

export const connectData = {
	connectcontent: "For information about prints, photography services, or project builds, submit an inquiry below."
}

export const connectImageData = {
	images: [
		{ image : "DSC00336.jpg"},
		{ image : "IMG_2764.jpg"},
		{ image : "IMG_4282-HDR-Pano.jpg"},
		{ image : "_DSC0102.jpg"},
		{ image : "_DSC2338.jpg"},
		{ image : "_DSC6130.jpg"},
		{ image : "_DSC7547.jpg"},
		{ image : "_DSC9386-HDR.jpg"},
		{ image : "_DSC9787.jpg"}
	]
}

export const homeData = {
	homeContent: "",
	slides: [		
		{ slideuri : "sq_DSC00178-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq_DSC00336.jpg", slidename : "" } ,
		{ slideuri : "sq_DSC00890.JPG", slidename : "" } ,
		{ slideuri : "sq_IMG_0046-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_0436-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_20190505_131911.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_20190729_201946.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_20190731_094017.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_2764.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_4282-HDR-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq_IMG_9759-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC0102.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC2338.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC2588.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC2590-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC2601.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC2838-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC2887.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC3589-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC3851.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC6099-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC6130.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC7547.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC8766-HDR.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC9386-HDR.jpg", slidename : "" } ,
		{ slideuri : "sq__DSC9787.jpg", slidename : "" } ,
		{ slideuri : "sq__MG_6709.jpg", slidename : "" } ,
		{ slideuri : "sq__MG_6738.jpg", slidename : "" } ,
		{ slideuri : "sq__MG_7242.jpg", slidename : "" } ,
		{ slideuri : "sq__MG_7635-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__SAM5342-HDR-Pano.jpg", slidename : "" } ,
		{ slideuri : "sq__SAM5727-HDR-Pano.jpg", slidename : "" } , 
		{ slideuri : "sq__DSC7868-HDR.jpg", slidename : "" } 
	]
}
