import React from "react";
import { Link } from "react-router-dom";

function FooterNav () {
    return (
            <div className="footernav-holder">
                <div className="footernav-element">
                    <Link to="/gallery" className="link">
                        gallery
                    </Link>
                </div>
                <div className="footernav-element">
                    <Link to="/print" className="link">
                        prints
                    </Link>
                </div>
                <div className="footernav-element">
                    <Link to="/build" className="link">
                        build
                    </Link>
                </div>
                <div className="footernav-element">
                    <Link to="/code" className="link">
                        code
                    </Link>
                </div>
                {/* <div className="footernav-element">
                    <Link to="/ignite" className="link">
                        ignite
                    </Link>
                </div>
                <div className="footernav-element">
                    <Link to="/illuminate" className="link">
                        illuminate
                    </Link>
                </div> */}
                <div className="footernav-element"><Link to="/about" className="link">
                        about
                    </Link>
                </div>
                <div className="footernav-element">
                    <Link to="/connect" className="link">
                        connect
                    </Link>
                </div>
            </div>
    );
}

export default FooterNav;