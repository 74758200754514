import React from "react";
import { homeData } from "../../data";
import Subheader from "../Header/Subheader";
import FilmStripView from "../SubComponents/FIlmStripView";


function Home () {
    return (
        <div>
            <div>
                <Subheader ismain="true"/>
            </div>
            <div className="content-wrapper">
                <div className="home-filmstrip">
                    <FilmStripView />
                </div>
                <div className="home-container">
                    <p>{ homeData.homeContent }</p>
                </div>
            </div>
        </div>
    );
}

export default Home;