import React, { Component } from "react";

class FilmStripImageView extends Component {
	render() {
		return (
			<div className="filmstrip-image-container"><img src={this.props.filmimageurl} alt={this.props.filmimagealt} /></div>
		);
	}
}

export default FilmStripImageView;