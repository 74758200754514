import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import PhotoModal from "../SubComponents/PhotoModal";

const GalleryPhotos = () => {
	const [photodata, setPhoto] = useState({showModal: false,
											photouri: ""});

	const showPhotoModal = e => {
		setPhoto({...e, showModal: !photodata.showModal});
	}

	const location = useLocation();
	const propsData = location.state;

	return(
			<div className="content-wrapper">
				<div className="galleryphotos-header">
					{<h2>Gallery: #{propsData.galleryname}</h2>}
				</div>
				<div className="galleryphotos-wrapper">
					{ propsData.photos.map((data, key) => {
						return (
							<div className="galleryphoto-container">
								<div className="galleryphoto-image" key={key}>
									<img src={data.photouri} alt={data.photoname} referrerPolicy="no-referrer" onClick={ e => {showPhotoModal(data);}} />
								</div>
							</div>
						)
					})}
				</div>
				<PhotoModal data={photodata} onClose={showPhotoModal} />
			</div>
	);
}

export default GalleryPhotos;