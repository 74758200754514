import React, { Component } from "react";

class AboutImageView extends Component {
	render() {
		return (
			<div className="about-images-container"><img src={this.props.aboutimguri} alt="" /></div>
		);
	}
}

export default AboutImageView;