import React, { Component } from "react";
import '../../styles/App.scss';

class PhotoModal extends Component {
	
	onClose = e => {
		this.props.onClose && this.props.onClose(e);
	};
	
	render() {
		
		if (!this.props.data.showModal) {
			return null;
		};
		
		return (
			<div className="photomodal-container">
				<div className="photomodal-close">
					<button onClick={e => { this.onClose(e);}} className="photobutton-close" type="button">X</button>
				</div>
				<img src={this.props.data.photouri} alt=""/>
				<div className="photomodal-details">
						<div className="photo-data">{this.props.data.photodate}</div>
						<div className="photo-name">{this.props.data.photoname}</div>
						<div className="photo-location">{this.props.data.photolocation}</div>
					</div>
			</div>
		);
	}
}

export default PhotoModal;