import React, { Component } from "react";
import { galleryData } from "../../data";
import GalleryView from "../SubComponents/GalleryView";
import Subheader from "../Header/Subheader";

class Gallery extends Component {
	render() {
		return (
			<div>
				<div>
					<Subheader title="Gallery"/>
				</div>
				<div className="content-wrapper">
					<div className="gallery-wrapper">
						{ galleryData.map((data, key) => {
							return (
								<GalleryView key={key} pic={data.coverphotouri} galleryname={data.galleryname} photos={data.photos} gallerytag={data.gallerytag}/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
};

export default Gallery;