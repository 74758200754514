import React from "react";
import Email from "../static/Email"
import Insta from "../static/Insta";
import LinkedIn from "../static/LinkedIn";
import YouTube from "../static/YouTube";
import FooterNav from "./FooterNav";

function Footer () {
    return (
        <div className="footer-wrapper">
            <div>
                <FooterNav />
            </div>
            <div className="footer-social">
                <Email />
                <Insta />
                <LinkedIn />
                <YouTube />
            </div>
            <div className="footer-copyright">
                <h5>2023, Blue Sky Catastrophe Interactive Arts and Media, LLC</h5>
            </div>
            
        </div>
    );
}

export default Footer;