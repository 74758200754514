import React, { Component } from "react";
import ProjectView from "../SubComponents/ProjectView";
import { projectData } from "../../data";
import Subheader from "../Header/Subheader";

class Code extends Component {
	render() {
		return (
			<div>
				<div>
					<Subheader title="Code"/>
				</div>
				<div className="content-wrapper">
					<div className="projects-wrapper">
						{ projectData.filter(project => project.projecttype === "code").map((data, key) => {
							return (
								<ProjectView key={key} 
										pic={data.pic} 
										repository={data.repository} 
										description={data.description} 
										projectdate={data.projectdate} 
										projectname={data.projectname} 
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
};

export default Code;