import React from "react";

function Subheader(props) {
    if (props.ismain) {
        return (
            <div className="header-container">                       
            </div>                
        );
    } else {
       return (
            <div className="header-container">
                <h2>{props.title}</h2>    
           </div>        
        );
    }
}

export default Subheader;