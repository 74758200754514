import React, { Component } from "react";
import { aboutData } from "../../data";
import { aboutImageData } from "../../data";
import Subheader from "../Header/Subheader";
import AboutImageView from "../SubComponents/AboutImageView";
import { randomNum } from "../../Helpers";

class About extends Component {
	state = {
        aboutDir: "/images/about/"
	};
 
    getAboutImages = () => {
        let content = [];
        let nums = [];
        let i = 0;

        do {
            const randVal = randomNum(25);
            if (!nums.includes(randVal)) {
                content.push(<AboutImageView aboutimguri={process.env.PUBLIC_URL + this.state.aboutDir + aboutImageData.tiles[randVal].photouri}/>);
                i++;
            }
            nums.push(randVal);
        }
        while (i < 12);

        return content;
    };

    render() {
        return (
            <div>
                <div>
                    <Subheader title="About"/>
                </div>
                <div className="content-wrapper">
                    <div className="about-wrapper">
                        <div className="about-images-wrapper">
                             {this.getAboutImages()}
                        </div>
                        <div className="about-deets">
                            <h3 className="blue-sky-about">About Blue Sky Catastrophe</h3>
                            <div className="about-top">
                                <p>{aboutData.aboutcontent}</p>
                                <p>{aboutData.aboutsummary}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default About;