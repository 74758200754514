import React from "react";
import { Link } from "react-router-dom";

function NavBar () {
    return (
        <div className="navigation">
            <div className="nav-home">
                <Link to="/" className="link">
                <div className="nav-home-image"></div>
                <div className="nav-title"><h2><span className="title-span">blue</span> sky catastrophe.</h2></div>
                </Link>
            </div>
            <div className="nav-holder">
                <p>
                    <Link to="/gallery" className="link">
                        gallery.
                    </Link>
                </p>
                <p>
                    <Link to="/print" className="link">
                        print.
                    </Link>
                </p>
                <p>
                    <Link to="/build" className="link">
                        build.
                    </Link>
                </p>
                <p>
                    <Link to="/code" className="link">
                        code.
                    </Link>
                </p>
                {/* <p><Link to="/ignite" className="link">
                        ignite.
                    </Link>
                </p>
                <p><Link to="/illuminate" className="link">
                        illuminate.
                    </Link>
                </p> */}
                <p><Link to="/about" className="link">
                        about.
                    </Link>
                </p>
                <p>
                    <Link to="/connect" className="link">
                        connect.
                    </Link>
                </p>
            </div>
        </div>
    );
}

export default NavBar;