import React, { Component } from "react";
import { connectData, connectImageData } from "../../data";
import * as emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha'
import Subheader from "../Header/Subheader";
import ConnectImageView from "../SubComponents/ConnectImageView";
import { randomNum, randomSort } from "../../Helpers";

class Connect extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			connect_email: "",	
			connect_name: "",
			connect_message: "",
			recaptchaLoad: false,
			isVerified: false,			
			connectDir: "/images/connect/"
		};
		
		this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
		this.verifiedRecaptcha = this.verifiedRecaptcha.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.resetForm = this.resetForm.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
	}
   
	onChange(value) {
		console.log('Captcha', value);		
	}

    handleInputChange(e) {
		this.setState({ [e.target.name]: e.target.value});
    }
	
    handleSubmit(e) {
        e.preventDefault();	
		console.log("Submit handled");	
		this.setState({ isVerified: true });
		const { isVerified } = this.state;
		console.log(this.state);
		
		if (isVerified) {
			console.log("Captcha verified");
			const templateParams = {
				from_name: this.state.connect_name,
				from_email: this.state.connect_email,
				reply_to: this.state.connect_email,
				to_name: 'Blue Sky Catastrophe',
				subject: '[Site] Request for information',
				message_html: this.state.connect_message,
			};
			
			emailjs.send(
				'service_po6eyx8',
				'template_08aff1g',
				templateParams,
				'user_K5v0XUnYTEEOnzzXxQAWP'
			);
			
			this.resetForm();
		}
    }

	resetForm() {
		this.setState({
			connect_email: "",
			connect_name: "",
			connect_message: "" 
		});
	};
	
	recaptchaLoaded() {
		console.log("recaptchaLoaded");
	  	this.setState({ recaptchaLoad: true });
	}

	verifiedRecaptcha(response) {
		console.log("verified recaptcha");
		if (response) {
			this.setState({ isVerified: true });
		}
	}
	
	numArray = () => {
		let nums = Array.from(Array(8).keys());
		nums.map(x => x);
		return nums.sort(randomSort);
	}

	getConnectImages = () => {
        let content = [];
        let nums = [];
        let i = 0;

		let numSet = this.numArray();

        do {
            const randVal = randomNum(9);
            content.push(<ConnectImageView connectimguri={process.env.PUBLIC_URL + this.state.connectDir +  "slice_" + i.toString() + "_" + connectImageData.images[numSet[i]].image}/>);
            nums.push(randVal);
			i++;
        }
        while (i < 8);

        return content;
    };

	render() {

		return (
			<div>
				<div>
					<Subheader title="Connect"/>
				</div>			
				<div className="content-wrapper">
					<div className="connect-wrapper">
						<div className="connect-deets">
							<div className="connect-text">
								<p>{connectData.connectcontent}</p>
							</div>
							<div className="connect-form">
								<form onSubmit={this.handleSubmit}>
									<input 
										type="text" 
										placeholder="name" 
										name="connect_name"
										onChange={this.handleInputChange}
										value={this.state.connect_name}
									/>
									<input 
										type="text" 
										name="connect_email"
										placeholder="email address" 
										value={this.state.connect_email}
										onChange={this.handleInputChange}
									/>
									<textarea
										type="text" 
										name="connect_message"
										placeholder="message" 
										onChange={this.handleInputChange}
										value={this.state.connect_message}
									/>
									<button type="submit">Send</button>
								</form>
							</div>
							<div className="connect-captcha">
								<ReCAPTCHA
									size="compact"
									sitekey="6LdbiSUkAAAAAGK931dB-o8MzXMKvcY4Cq7APRkU"
									render="explicit"
									onChange={this.onChange}
									onloadCallback={this.recaptchaLoaded}
									verifyCallback={this.verifiedRecaptcha}									
								/>	
							</div>
						</div>
						<div className="connect-images-wrapper">
                             {this.getConnectImages()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Connect;