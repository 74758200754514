import React, { Component } from "react";

class ConnectImageView extends Component {
	render() {	
		return (
			<div className="connect-image-container"><img src={this.props.connectimguri} alt="" /></div>
		);
	}
}

export default ConnectImageView;